import React, { Component } from 'react'
import { Grid, Box, IconButton, Card, CardContent, Collapse } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { withStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { flowRight as compose } from 'lodash'

import ListField from '../ui/ListField'
import { withRouter } from 'react-router-dom'
import { CLIENT_TITLE } from '../../lib/constants'

const styles = theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)',
      cursor: 'pointer'
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
})

class ClientItem extends Component {
  state = {
    isExpanded: false
  }

  handleCollapse = event => {
    event.stopPropagation()
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  handleEdit = event => {
    event.preventDefault()
    const { client } = this.props
    const location = client.id ? `/edit/${client.id}` : '/create'
    this.props.history.push(location)
  }

  render() {
    const { client, classes } = this.props
    const { isExpanded } = this.state

    const fullName = [client.lastName, client.firstName, client.middleName].filter(name => !!name).join(' ')

    return (
      <Box my={1}>
        <Card className={classes.root} onClick={this.handleEdit}>
          <CardContent>
            <Grid container>
              <Grid item xs={10}>
                <Grid container spacing={1}>
                  <Grid item md={3} xs={12}>
                    <ListField title={CLIENT_TITLE.fullName} value={fullName} emptyMask='-' />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <ListField title={CLIENT_TITLE.iin} value={client.iin} />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <ListField title={CLIENT_TITLE.phoneNumber} value={client.phoneNumber} />
                  </Grid>
                </Grid>
                <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                  <Box mt={2}>
                    <Grid container spacing={1}>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.additionalPhoneNumber} value={client.additionalPhoneNumber} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.email} value={client.email} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.oneCId} value={client.oneCId} emptyMask='-' />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.dateOfBirth} value={client.dateOfBirth} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.gender} value={client.gender} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.language} value={client.language} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.countryOfResidence} value={client.countryOfResidence} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.region} value={client.region} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.address} value={client.address} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.receiveAds} value={client.receiveAds} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.documents} value={client.documents} />
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <ListField title={CLIENT_TITLE.bankAccounts} value={client.bankAccounts} />
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </Grid>
              <Grid item xs={2}>
                <Box display='flex' justifyContent='flex-end'>
                  <IconButton
                    onClick={this.handleCollapse}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: isExpanded
                    })}
                    aria-expanded={isExpanded}
                    aria-label='Полная информация'
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    )
  }
}

export default compose(withRouter, withStyles(styles))(ClientItem)
