import languages from '@cospired/i18n-iso-languages'
import { capitalizeFirstLetter } from './utils'
languages.registerLocale(require('@cospired/i18n-iso-languages/langs/ru.json'))

export const CLIENT_TITLE = {
  iin: 'ИИН',
  oneCId: 'Номер в 1С',
  firstName: 'Имя',
  lastName: 'Фамилия',
  middleName: 'Отчество',
  fullName: 'ФИО',
  dateOfBirth: 'Дата рождения',
  gender: 'Пол',
  language: 'Язык',
  phoneNumber: 'Номер телефона',
  additionalPhoneNumber: 'Доп. номер телефона',
  email: 'Email адрес',
  countryOfResidence: 'Страна резидента',
  region: 'Регион',
  address: 'Адрес',
  receiveAds: 'Получаeт рекламу',
  documents: 'Паспортные данные',
  bankAccounts: 'Банковский счет'
}

export const COUNTRIES = [
  { countryName: 'Австралия' },
  { countryName: 'Австрия' },
  { countryName: 'Азербайджан' },
  { countryName: 'Албания' },
  { countryName: 'Алжир' },
  { countryName: 'Ангола' },
  { countryName: 'Андорра' },
  { countryName: 'Антигуа и Барбуда' },
  { countryName: 'Аргентина' },
  { countryName: 'Армения' },
  { countryName: 'Афганистан' },
  { countryName: 'Багамские Острова' },
  { countryName: 'Бангладеш' },
  { countryName: 'Барбадос' },
  { countryName: 'Бахрейн' },
  { countryName: 'Белоруссия' },
  { countryName: 'Белиз' },
  { countryName: 'Бельгия' },
  { countryName: 'Бенин' },
  { countryName: 'Болгария' },
  { countryName: 'Боливия' },
  { countryName: 'Босния и Герцеговина' },
  { countryName: 'Ботсвана' },
  { countryName: 'Бразилия' },
  { countryName: 'Бруней' },
  { countryName: 'Буркина-Фасо' },
  { countryName: 'Бурунди' },
  { countryName: 'Бутан' },
  { countryName: 'Вануату' },
  { countryName: 'Великобритания' },
  { countryName: 'Венгрия' },
  { countryName: 'Венесуэла' },
  { countryName: 'Восточный Тимор' },
  { countryName: 'Вьетнам' },
  { countryName: 'Габон' },
  { countryName: 'Гаити' },
  { countryName: 'Гайана' },
  { countryName: 'Гамбия' },
  { countryName: 'Гана' },
  { countryName: 'Гватемала' },
  { countryName: 'Гвинея' },
  { countryName: 'Гвинея-Бисау' },
  { countryName: 'Германия' },
  { countryName: 'Гондурас' },
  { countryName: 'Гренада' },
  { countryName: 'Греция' },
  { countryName: 'Грузия' },
  { countryName: 'Дания' },
  { countryName: 'Джибути' },
  { countryName: 'Доминика' },
  { countryName: 'Доминиканская Республика' },
  { countryName: 'Египет' },
  { countryName: 'Замбия' },
  { countryName: 'Зимбабве' },
  { countryName: 'Израиль' },
  { countryName: 'Индия' },
  { countryName: 'Индонезия' },
  { countryName: 'Иордания' },
  { countryName: 'Ирак' },
  { countryName: 'Иран' },
  { countryName: 'Ирландия' },
  { countryName: 'Исландия' },
  { countryName: 'Испания' },
  { countryName: 'Италия' },
  { countryName: 'Йемен' },
  { countryName: 'Кабо-Верде' },
  { countryName: 'Казахстан' },
  { countryName: 'Камбоджа' },
  { countryName: 'Камерун' },
  { countryName: 'Канада' },
  { countryName: 'Катар' },
  { countryName: 'Кения' },
  { countryName: 'Кипр' },
  { countryName: 'Киргизия' },
  { countryName: 'Кирибати' },
  { countryName: 'Китай' },
  { countryName: 'Колумбия' },
  { countryName: 'Коморы' },
  { countryName: 'Республика Конго' },
  { countryName: 'Демократическая Республика Конго' },
  { countryName: 'КНДР' },
  { countryName: 'Республика Корея' },
  { countryName: 'Коста-Рика' },
  { countryName: 'Кот-д’Ивуар' },
  { countryName: 'Куба' },
  { countryName: 'Кувейт' },
  { countryName: 'Лаос' },
  { countryName: 'Латвия' },
  { countryName: 'Лесото' },
  { countryName: 'Либерия' },
  { countryName: 'Ливан' },
  { countryName: 'Ливия' },
  { countryName: 'Литва' },
  { countryName: 'Лихтенштейн' },
  { countryName: 'Люксембург' },
  { countryName: 'Маврикий' },
  { countryName: 'Мавритания' },
  { countryName: 'Мадагаскар' },
  { countryName: 'Малави' },
  { countryName: 'Малайзия' },
  { countryName: 'Мали' },
  { countryName: 'Мальдивы' },
  { countryName: 'Мальта' },
  { countryName: 'Марокко' },
  { countryName: 'Маршалловы Острова' },
  { countryName: 'Мексика' },
  { countryName: 'Мозамбик' },
  { countryName: 'Молдавия' },
  { countryName: 'Монако' },
  { countryName: 'Монголия' },
  { countryName: 'Мьянма' },
  { countryName: 'Намибия' },
  { countryName: 'Науру' },
  { countryName: 'Непал' },
  { countryName: 'Нигер' },
  { countryName: 'Нигерия' },
  { countryName: 'Нидерланды' },
  { countryName: 'Никарагуа' },
  { countryName: 'Новая Зеландия' },
  { countryName: 'Норвегия' },
  { countryName: 'ОАЭ' },
  { countryName: 'Оман' },
  { countryName: 'Пакистан' },
  { countryName: 'Палау' },
  { countryName: 'Панама' },
  { countryName: 'Папуа — Новая Гвинея' },
  { countryName: 'Парагвай' },
  { countryName: 'Перу' },
  { countryName: 'Польша' },
  { countryName: 'Португалия' },
  { countryName: 'Россия' },
  { countryName: 'Руанда' },
  { countryName: 'Румыния' },
  { countryName: 'Сальвадор' },
  { countryName: 'Самоа' },
  { countryName: 'Сан-Марино' },
  { countryName: 'Сан-Томе и Принсипи' },
  { countryName: 'Саудовская Аравия' },
  { countryName: 'Северная Македония' },
  { countryName: 'Сейшельские Острова' },
  { countryName: 'Сенегал' },
  { countryName: 'Сент-Винсент и Гренадины' },
  { countryName: 'Сент-Китс и Невис' },
  { countryName: 'Сент-Люсия' },
  { countryName: 'Сербия' },
  { countryName: 'Сингапур' },
  { countryName: 'Сирия' },
  { countryName: 'Словакия' },
  { countryName: 'Словения' },
  { countryName: 'США' },
  { countryName: 'Соломоновы Острова' },
  { countryName: 'Сомали' },
  { countryName: 'Судан' },
  { countryName: 'Суринам' },
  { countryName: 'Сьерра-Леоне' },
  { countryName: 'Таджикистан' },
  { countryName: 'Таиланд' },
  { countryName: 'Танзания' },
  { countryName: 'Того' },
  { countryName: 'Тонга' },
  { countryName: 'Тринидад и Тобаго' },
  { countryName: 'Тувалу' },
  { countryName: 'Тунис' },
  { countryName: 'Туркмения' },
  { countryName: 'Турция' },
  { countryName: 'Уганда' },
  { countryName: 'Узбекистан' },
  { countryName: 'Украина' },
  { countryName: 'Уругвай' },
  { countryName: 'Федеративные Штаты Микронезии' },
  { countryName: 'Фиджи' },
  { countryName: 'Филиппины' },
  { countryName: 'Финляндия' },
  { countryName: 'Франция' },
  { countryName: 'Хорватия' },
  { countryName: 'Центральноафриканская Республика' },
  { countryName: 'Чад' },
  { countryName: 'Черногория' },
  { countryName: 'Чехия' },
  { countryName: 'Чили' },
  { countryName: 'Швейцария' },
  { countryName: 'Швеция' },
  { countryName: 'Шри-Ланка' },
  { countryName: 'Эквадор' },
  { countryName: 'Экваториальная Гвинея' },
  { countryName: 'Эритрея' },
  { countryName: 'Эсватини' },
  { countryName: 'Эстония' },
  { countryName: 'Эфиопия' },
  { countryName: 'ЮАР' },
  { countryName: 'Южный Судан' },
  { countryName: 'Ямайка' },
  { countryName: 'Япония' }
]

const LANGUAGES_JSON = languages.getNames('ru')
export const LANGUAGES = Object.keys(LANGUAGES_JSON).map(code => ({ code: code.toUpperCase(), title: capitalizeFirstLetter(LANGUAGES_JSON[code]) }))

export const GENDERS = [
  { code: 'F', title: 'Женский' },
  { code: 'M', title: 'Мужской' }
]

export const SUCCESS_MESSAGE = 'Операция выполнена успешно!'

export const PHONE_MASK = '8 (999) 999-99-99'
