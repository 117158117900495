import React from 'react'
import { AppBar as AppBarMaterial, Toolbar, IconButton, Typography, Button } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { withRouter } from 'react-router-dom'
import { setJWTToken } from '../../lib/utils'

const AppBar = ({ history }) => {
  const signOut = () => {
    setJWTToken()
    history.push('/signin')
  }

  return (
    <AppBarMaterial position='static' style={{ backgroundColor: '#333' }}>
      <Toolbar>
        <IconButton edge='start' color='inherit' aria-label='menu'>
          <MenuIcon />
        </IconButton>
        <Typography variant='h6' style={{ flexGrow: '1' }}>
          Aster Clients
        </Typography>
        <Button color='inherit' onClick={signOut}>
          Выход
        </Button>
      </Toolbar>
    </AppBarMaterial>
  )
}

export default withRouter(AppBar)
