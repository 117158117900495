import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  listField: {
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px dotted #ddd",
      marginBottom: theme.spacing(2),
      justifyContent: "space-between",
      flexGrow: 1,
      minWidth: 200,
      flexDirection: "row"
    },
    flexDirection: "column"
  },
  title: {
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-end",
      marginBottom: "-2px"
    }
  },
  value: {
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      alignSelf: "flex-end",
      textAlign: 'right',
      marginLeft: theme.spacing(2),
      marginBottom: "-2px"
    }
  }
}));

function ListField({ title, value, emptyMask = "-" }) {
  const classes = useStyles();
  return (
    <Box className={classes.listField} display="flex">
      <div className={classes.title}>{title}</div>
      <div className={classes.value}>{value ? value : emptyMask}</div>
    </Box>
  );
}

export default ListField;
