import instance from './axios'

export const parseYupErrors = ({ inner }) => {
  if (inner && inner.length > 0) {
    return inner.reduce((res, error) => {
      res[error.path] = error.message
      return res
    }, {})
  }

  return {}
}

export const capitalizeFirstLetter = string => `${string.charAt(0).toUpperCase()}${string.slice(1)}`.trim()

export const setJWTToken = token => {
  if (token) {
    localStorage.setItem('token', token)
    instance.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem('token')}`
  } else {
    localStorage.removeItem('token')
    instance.defaults.headers.common['Authorization'] = undefined
  }
}
