import * as yup from 'yup'
import moment from 'moment'

export default yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  middleName: yup.string().required(),
  // eslint-disable-next-line no-template-curly-in-string
  dateOfBirth: yup.string().test('dateOfBirth', 'Укажите правильную дату', value => {
    return moment(value, 'DD.MM.YYYY').format('DD.MM.YYYY') === value
  }),
  iin: yup.string().matches(/^\d{12}$/, 'Введите правильный ИИН'),
  resident: yup.bool().required(),
  countryOfResidence: yup.string().when(['resident'], {
    is: true,
    then: yup.string(),
    otherwise: yup.string().required(`Необходимо указать страну или поставить галочку 'Резидент?'`)
  }),
  phoneNumber: yup
    .string()
    .matches(/^8 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, 'Номер телефона не валидный')
    .required(),
  additionalPhoneNumber: yup.lazy(value => (!value ? yup.string() : yup.string().matches(/^8 \(\d{3}\) \d{3}-\d{2}-\d{2}$/, 'Phone number is not valid'))),
  email: yup.string().email()
})
