import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import cn from 'classnames'

const Loader = ({ classes, full, size = 30 }) => {
  return (
    <div className={cn(classes.root, full && classes.full)}>
      <CircularProgress color='inherit' size={size} thickness={4} />
    </div>
  )
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    color: grey[600]
  },
  full: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: theme.zIndex.tooltip,
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff'
  }
})

export default withStyles(styles)(Loader)
