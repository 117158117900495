import React, { PureComponent } from 'react'
import { Paper, TextField, MenuItem, Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Downshift from 'downshift'
import { deburr } from 'lodash'
import Loader from '../ui/loaders/Loader'

class AutocompleteField extends PureComponent {
  renderInput = ({ InputProps, ref, ...other }) => {
    const { classes } = this.props

    return (
      <TextField
        InputProps={{
          inputRef: ref,
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput
          },
          ...InputProps
        }}
        {...other}
      />
    )
  }

  renderSuggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItem }) => {
    const label = suggestion[this.props.labelKey]
    const isHighlighted = highlightedIndex === index
    const isSelected = (this.itemToString(selectedItem) || '').indexOf(label) > -1
    return (
      <MenuItem {...itemProps} key={label} selected={isHighlighted} component='div' style={{ fontWeight: isSelected ? 500 : 400 }}>
        {label}
      </MenuItem>
    )
  }

  getSuggestions = search => {
    const { suggestions, labelKey, autoOpen, isAsync } = this.props

    const inputValue = deburr(search.trim()).toLowerCase()
    const inputLength = inputValue.length
    let count = 0

    if (autoOpen && suggestions.length < 10) {
      return suggestions
    }

    return inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          const isFound = isAsync ? true : suggestion[labelKey] && suggestion[labelKey].slice(0, inputLength).toLowerCase() === inputValue
          const keep = count < 10 && isFound

          if (keep) {
            count += 1
          }

          return keep
        })
  }

  handleKeyDown = event => {
    const { inputValue, selectedItem } = this.state;
    if (selectedItem.length && !inputValue.length && event.key === 'Backspace') {
      this.setState({
        selectedItem: selectedItem.slice(0, selectedItem.length - 1),
      });
    }
  };

  itemToString = item => {
    return (item && item[this.props.labelKey]) || ''
  }

  handleSelect = (item, ...args) => {
    const { onSelect, valueKey, labelKey, name } = this.props
    onSelect && onSelect(item[valueKey || labelKey], name, ...args)
  }

  handleInputValueChange = (value, ...args) => {
    if (value.length === 0) {
      const { onSelect, name } = this.props
      onSelect && onSelect(undefined, name, ...args)
    }
  }

  render() {
    const { classes, inputLabel, suggestions, valueKey, placeholder, multiple, labelKey, value, autoOpen = false, onDelete, loading, disabled } = this.props

    return (
      <Downshift
        selectedItem={value ? suggestions.find(item => item[valueKey || labelKey] === value) : {}}
        onSelect={this.handleSelect}
        onInputValueChange={this.handleInputValueChange}
        itemToString={this.itemToString}
      >
        {({ getInputProps, getItemProps, getMenuProps, highlightedIndex, inputValue, isOpen, openMenu, selectedItem }) => {
          return (
            <div className={classes.container}>
              {this.renderInput({
                fullWidth: true,
                label: inputLabel,
                InputProps: getInputProps({
                  placeholder,
                  disabled,
                  onFocus: autoOpen ? openMenu : undefined,
                  startAdornment:
                    multiple && selectedItem.map(item => <Chip key={item[labelKey]} label={item[labelKey]} className={classes.chip} onDelete={onDelete && onDelete(item)} />)
                })
              })}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {loading && (
                      <MenuItem component='div' disabled>
                        <Loader size={28} />
                      </MenuItem>
                    )}
                    {this.getSuggestions(inputValue).map((suggestion, index) =>
                      this.renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion }),
                        highlightedIndex,
                        selectedItem
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          )
        }}
      </Downshift>
    )
  }
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    maxHeight: 200,
    overflow: 'auto'
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`
  }
})

export default withStyles(styles)(AutocompleteField)
