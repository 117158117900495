import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0089d0'
    },
    secondary: {
      main: '#555'
    },
    success: {
      main: '#249e36'
    },
    hover: {
      main: '#333'
    }
  }
})

export default theme
