import React, { PureComponent } from 'react'
import { TextField, Button, Box } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import RefreshIcon from '@material-ui/icons/Refresh'
import PhoneInput from '../form/PhoneInput'

class ClientFilter extends PureComponent {
  state = { form: {} }

  handleChange = fieldName => event => {
    event.preventDefault()
    this.setState({ form: { ...this.state.form, [fieldName]: event.target.value } })
  }

  handleReset = () => {
    this.setState({ form: {} })
    this.props.onSearch && this.props.onSearch({})
  }

  handleSubmit = event => {
    event.preventDefault()
    this.props.onSearch && this.props.onSearch(this.state.form)
  }

  render() {
    const {
      form: { iin = '', phoneNumber = '' }
    } = this.state

    return (
      <div className='search-container'>
        <form onSubmit={this.handleSubmit}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box>
              <TextField label='ИИН' className='input' value={iin} onChange={this.handleChange('iin')} />
              <PhoneInput name='phoneNumber' margin={'none'} value={phoneNumber} label={'Номер телефона'} onChange={this.handleChange('phoneNumber')} />
            </Box>
            <Box>
              <Button className='button_small button_green_color' color='primary' variant='contained' type='submit'>
                <SearchIcon />
              </Button>
              <Button className='button_small button_grey_color' color='primary' variant='contained' type='button' onClick={this.handleReset}>
                <RefreshIcon />
              </Button>
            </Box>
          </Box>
        </form>
      </div>
    )
  }
}

export default ClientFilter
