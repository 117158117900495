import React, { Component } from 'react'
import { Grid, Paper, Typography, TextField, IconButton, Box, Button, Dialog, DialogTitle } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { AUTH_API_URL } from '../lib/env'
import instance from '../lib/axios'
import { setJWTToken } from '../lib/utils'
import Axios from 'axios'

class SignIn extends Component {
  state = {
    username: '',
    password: '',
    isInfoDialogOpen: false,
    authErrorText: ''
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  componentDidMount() {
    this.checkUserRoleAndRedirect()
  }

  checkUserRoleAndRedirect = () =>
    instance.get(AUTH_API_URL + '/users/getUserByToken').then(({ data }) => {
      const roles = (data && data.roles) || []
      const role = roles.find(({ code, project }) => code === 'ADMIN' && project.code === 'CL')

      if (role) {
        this.props.history.push('/')
      }
    })

  handleSignIn = async event => {
    event.preventDefault()
    try {
      const response = await Axios.post(AUTH_API_URL + '/authenticate', {
        username: this.state.username,
        password: this.state.password
      })
      setJWTToken(response.data.token)
      await this.checkUserRoleAndRedirect()
      this.props.history.push('/')
    } catch (error) {
      console.log({ error })
      if (error.response.status === 401) {
        alert('Неверный логин или пароль. Проверьте, нет ли опечаток.')
      }
    }
  }

  handleCloseInfoDialog() {
    this.setState({
      isInfoDialogOpen: false
    })
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <Grid container spacing={0} direction='column' alignItems='center' justify='center' style={{ minHeight: '70vh' }}>
          <Grid item xs={12}>
            <form onSubmit={this.handleSignIn.bind(this)}>
              <Paper
                style={{
                  padding: '60px',
                  paddingBottom: '60px',
                  minWidth: '250px',
                  maxWidth: '300px'
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='h5' component='h3'>
                      Авторизация
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      id='username'
                      label={'Имя пользователя'}
                      type='text'
                      autoComplete='off'
                      value={this.state.username}
                      onChange={this.handleChange('username')}
                      margin='normal'
                      required
                    />
                  </Grid>
                  <Grid item xs={12} style={{ paddingBottom: '20px' }}>
                    <TextField
                      style={{ width: '100%' }}
                      id='password'
                      label={'Пароль'}
                      type='password'
                      value={this.state.password}
                      autoComplete='off'
                      onChange={this.handleChange('password')}
                      margin='normal'
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button className='button' disabled={!this.state.username === '' && this.state.password === ''} style={{ marginTop: '30px', width: '100%' }} type='submit'>
                      Вход в систему
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>

        <Dialog width={300} onClose={this.handleCloseInfoDialog.bind(this)} aria-labelledby='simple-dialog-title' open={this.state.isInfoDialogOpen}>
          <DialogTitle id='simple-dialog-title'>
            <Grid container>
              <Grid item xs={6}>
                Ошибка
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <IconButton aria-label='Close' onClick={this.handleCloseInfoDialog.bind(this)}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <Box p={1}>
            <Grid>
              <Grid item xs={12}>
                {this.state.authErrorText}
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </div>
    )
  }
}

export default SignIn
