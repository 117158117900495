import React from 'react'
import { Box } from '@material-ui/core'
import ClientItem from './ClientItem'

const ClientList = ({ clients = [] }) => {
  return (
    <Box>
      {clients.map(client => (
        <ClientItem key={`client-list-${client.id}`} client={client} />
      ))}
    </Box>
  )
}

export default ClientList
