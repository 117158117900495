import React from 'react'
import InputMask from 'react-input-mask'
import { FormControl, InputLabel, Input, InputAdornment, IconButton, FormHelperText } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'
import { random } from 'lodash'

const PhoneInput = ({ required, id, mask = '8 (999) 999-99-99', fullWidth, margin = 'normal', value, name, label, helperText, error, onChange, onClear }) => {
  const idInput = id ? id : `formatted-phone-${random(0 - 1000)}`

  return (
    <FormControl required={required} error={!!error} margin={margin} fullWidth={fullWidth}>
      {!!label && <InputLabel htmlFor={idInput}>{label}</InputLabel>}
      <InputMask mask={mask} value={value || ''} onChange={onChange}>
        {inputProps => (
          <Input
            {...inputProps}
            type='tel'
            name={name}
            id={idInput}
            endAdornment={
              onClear &&
              !!value && (
                <InputAdornment position='end'>
                  <IconButton aria-label='Clear value' onClick={onClear}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        )}
      </InputMask>
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default PhoneInput
