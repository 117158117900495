import React, { Component } from 'react'
import ClientFilter from '../../components/client/ClientFilter'
import instance from '../../lib/axios'
import ClientList from '../../components/client/ClientList'
import { CLIENT_API_URL } from '../../lib/env'
import { LinearProgress, Button, Box } from '@material-ui/core'
import Pagination from '../../components/ui/pagination/Pagination'
import { withStyles } from '@material-ui/styles'

const DEFAULT_ROW_LIMIT = 10
const ROW_LIMIT_OPTIONS = [10, 20, 50]

class Clients extends Component {
  state = {
    clients: [],
    page: 0,
    limit: DEFAULT_ROW_LIMIT,
    count: 0,
    filter: {}
  }

  handleSearch = search => {
    this.setState({ filter: search }, () => {
      this.loadData()
    })
  }

  componentDidMount() {
    this.loadData()
  }

  handleChangePage = (event, newPage) => {
    event.preventDefault()
    this.setState({ page: newPage }, () => {
      this.loadData()
    })
  }

  handleChangeRowsPerPage = event => {
    event.preventDefault()
    this.setState({ page: 0, limit: parseInt(event.target.value, 10) }, () => {
      this.loadData()
    })
  }

  loadData = async () => {
    const { limit, page, filter } = this.state
    this.setState({ loading: true })
    try {
      const { listClients: clients, countFiltered: count } = await instance
        .post(`${CLIENT_API_URL}/clients/list`, {
          limit,
          offset: page * limit,
          ...filter
        })
        .then(response => response.data)
      this.setState({ loading: false, clients, count })
    } catch (error) {
      console.log({ error })
      this.setState({ loading: false })
    }
  }

  handleCreate = () => {
    this.props.history.push('/create')
  }

  render() {
    const { classes } = this.props
    const { clients, count, page, limit, loading } = this.state
    return (
      <div>
        <h1>Клиенты</h1>
        <Box my={2}>
          <Button className={classes.success} variant='contained' onClick={this.handleCreate}>
            Добавить
          </Button>
        </Box>
        <ClientFilter onSearch={this.handleSearch} />
        {!!loading && <LinearProgress />}
        <Pagination
          rowsPerPageOptions={ROW_LIMIT_OPTIONS}
          count={count}
          rowsPerPage={limit}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage=''
        />
        <ClientList clients={clients} />
        <Pagination
          rowsPerPageOptions={ROW_LIMIT_OPTIONS}
          count={count}
          rowsPerPage={limit}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage=''
        />
      </div>
    )
  }
}

const styles = theme => ({
  success: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.hover.main
    }
  }
})

export default withStyles(styles)(Clients)
