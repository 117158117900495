import React, { Component } from 'react'
import { Container, Box, LinearProgress } from '@material-ui/core'

import * as queryString from 'query-string'

import './sass/main.sass'
import { Route } from 'react-router-dom'
import Clients from './pages/Clients/Clients'
import AppBar from './components/ui/AppBar'
import ClientEdit from './pages/Clients/ClientEdit'
import instance from './lib/axios'
import { AUTH_API_URL } from './lib/env'
import SignIn from './pages/signin'
import { setJWTToken } from './lib/utils'

class App extends Component {
  state = {
    appBarVisible: true
  }

  setToken = () => {
    return new Promise((resolve, reject) => {
      try {
        const values = queryString.parse(this.props.location.search)
        if (values.token) {
          setJWTToken(values.token)
          this.setState({ appBarVisible: false }, () => {
            resolve()
          })
        } else {
          resolve()
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  async componentDidMount() {
    await this.setToken()
    this.checkAuth()
  }

  checkAuth = async () => {
    try {
      const data = await instance.get(`${AUTH_API_URL}/users/getUserByToken`).then(response => {
        return response.data
      })
      const roles = (data && data.roles) || []
      const role = roles.find(({ code, project }) => code === 'ADMIN' && project.code === 'CL')
      if (!role) {
        throw new Error('403 Permission denied!')
      }
      this.setState({ isAuthenticated: true, isLoggingIn: false })
    } catch (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        localStorage.removeItem('token')
        this.props.history.push('/signin')
      }
    }
  }

  render() {
    const { appBarVisible, isLoggingIn } = this.state

    if (isLoggingIn) {
      return <LinearProgress />
    }

    return (
      <Box flexGrow='1'>
        {appBarVisible && <AppBar />}
        <Container className='main'>
          <Route exact path='/' component={Clients} />
          <Route exact path='/edit/:id' component={ClientEdit} />
          <Route exact path='/create' component={ClientEdit} />
          <Route exact path='/signin' component={SignIn} />
        </Container>
      </Box>
    )
  }
}

export default App
