import React from "react";
import { Box, Table, TableFooter, TableRow, TablePagination } from "@material-ui/core";
import TableActions from "./TableActions";

function Pagination({ rowsPerPageOptions, count, rowsPerPage, page, onChangePage, onChangeRowsPerPage, labelRowsPerPage = "" }) {
  return (
    <Box display="flex" justifyContent="flex-end">
      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              labelRowsPerPage=""
              ActionsComponent={TableActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  );
}

export default Pagination;
