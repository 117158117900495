import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import './index.css'
import { ThemeProvider } from '@material-ui/styles'
import theme from './config/theme'
import { SnackbarProvider } from 'notistack'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <SnackbarProvider>
        <BrowserRouter>
          <Switch>
            <Route exact path='*' component={App} />
          </Switch>
        </BrowserRouter>
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
