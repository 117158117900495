import React from 'react'
import { FormControl, InputLabel, Input, FormHelperText } from '@material-ui/core'

const FormField = ({ name, value, label, error, helperText, type = 'text', onChange, required = false, fullWidth = true, ...inputProps }) => {
  return (
    <FormControl margin='normal' required={required} error={!!error} fullWidth={fullWidth}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Input id={name} name={name} value={value || ''} spellCheck='false' type={type} onChange={onChange} {...inputProps} />
      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default FormField
