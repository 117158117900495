import React, { Component } from 'react'
import instance from '../../lib/axios'
import { CLIENT_API_URL } from '../../lib/env'
import { withSnackbar } from 'notistack'
import { Paper, Grid, Checkbox, FormGroup, FormControlLabel, Box, Button, FormControl, FormHelperText } from '@material-ui/core'
import FormField from '../../components/form/FormField'
import { CLIENT_TITLE, COUNTRIES, LANGUAGES, GENDERS, SUCCESS_MESSAGE } from '../../lib/constants'
import { withStyles } from '@material-ui/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import AutocompleteField from '../../components/form/AutocompleteField'
import PhoneInput from '../../components/form/PhoneInput'
import validation from './ClientEdit.validation'
import { parseYupErrors } from '../../lib/utils'
import Loader from '../../components/ui/loaders/Loader'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'

const styles = theme => ({
  checkbox: {
    marginTop: 28
  },

  backButton: {
    backgroundColor: '#555'
  },

  buttonRight: {
    marginRight: theme.spacing(2)
  },
  success: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.hover.main
    }
  }
})

class ClientEdit extends Component {
  state = {
    client: {},
    form: {},
    errors: {}
  }

  componentDidMount() {
    this.fetchUser()
  }

  fetchUser = () => {
    const {
      match: {
        params: { id }
      },
      history,
      enqueueSnackbar
    } = this.props

    if (id) {
      this.setState({ loading: true })
      instance
        .get(`${CLIENT_API_URL}/clients/getById/${id}`)
        .then(response => {
          this.setState({
            loading: false,
            client: response.data,
            form: Object.keys(response.data)
              .filter(key => !!response.data[key])
              .reduce((p, key) => ({ ...p, [key]: response.data[key] }), {})
          })
        })
        .catch(error => {
          enqueueSnackbar(error.message, {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          })
          this.setState({ loading: false })
          history.goBack()
        })
    }
  }

  handleInputChange = event => {
    this.setState({ form: { ...this.state.form, [event.target.name]: event.target.value } })
  }

  handleDateChange = field => (mDate, value) => {
    this.setState({ form: { ...this.state.form, [field]: value } })
  }

  handleCheckBoxChange = (field, event, value) => {
    this.setState({ form: { ...this.state.form, [field]: value } })
  }

  handleAutocompleteSelect = (value, key) => {
    this.setState({ form: { ...this.state.form, [key]: value } })
  }

  handlePhoneInputChange = ({ target }) => {
    let phone = target.value
    this.setState({ form: { ...this.state.form, [target.name]: phone } })
  }

  handleSubmit = event => {
    event.preventDefault()
    const { enqueueSnackbar, history } = this.props
    const { form } = this.state
    this.setState({ errors: {} })
    try {
      validation.validateSync(form, { abortEarly: false })
    } catch (error) {
      const errors = parseYupErrors(error)

      this.setState({ errors })
      return false
    }
    instance
      .post(`${CLIENT_API_URL}/clients`, form)
      .then(response => {
        enqueueSnackbar(SUCCESS_MESSAGE, {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
        history.push('/')
      })
      .catch(error => {
        enqueueSnackbar(error.message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          }
        })
      })
  }

  render() {
    const {
      match: {
        params: { id }
      },
      classes
    } = this.props
    const { form, client, loading, errors } = this.state
    const title = id ? `Редактирование ${`${client.lastName} ${client.firstName} ${client.middleName}`.trim()}` : 'Добавить нового клиента'

    if (loading) {
      return <Loader />
    }

    return (
      <div>
        <div className='car-view__title'>
          <Button className='button_small' variant='contained' color='secondary' onClick={() => this.props.history.goBack()}>
            <ChevronLeftIcon />
          </Button>
          <h1 style={{ display: 'inline-block' }}>{title}</h1>
        </div>
        <form onSubmit={this.handleSubmit}>
          <Paper>
            <Box p={3}>
              <Grid container spacing={2}>
                <Grid item sm={4} md={3} xs={12}>
                  <FormGroup>
                    <FormField name='lastName' error={errors.lastName} value={form.lastName} label={CLIENT_TITLE.lastName} required onChange={this.handleInputChange} />
                  </FormGroup>
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <FormField name='firstName' error={errors.firstName} value={form.firstName} label={CLIENT_TITLE.firstName} required onChange={this.handleInputChange} />
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <FormField name='middleName' value={form.middleName} label={CLIENT_TITLE.middleName} onChange={this.handleInputChange} />
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <FormField name='iin' error={errors.iin} value={form.iin} label={CLIENT_TITLE.iin} onChange={this.handleInputChange} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={4} md={3} xs={12}>
                  <PhoneInput
                    fullWidth
                    name='phoneNumber'
                    value={form.phoneNumber}
                    label={CLIENT_TITLE.phoneNumber}
                    error={errors.phoneNumber}
                    onChange={this.handlePhoneInputChange}
                  />
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <PhoneInput
                    fullWidth
                    name='additionalPhoneNumber'
                    value={form.additionalPhoneNumber}
                    label={CLIENT_TITLE.additionalPhoneNumber}
                    error={errors.additionalPhoneNumber}
                    onChange={this.handlePhoneInputChange}
                  />
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <FormField name='email' error={errors.email} value={form.email} label={CLIENT_TITLE.email} onChange={this.handleInputChange} />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={4} md={3} xs={12}>
                  {/* <FormField name='dateOfBirth' error={errors.dateOfBirth} value={form.dateOfBirth} label={CLIENT_TITLE.dateOfBirth} onChange={this.handleInputChange} /> */}
                  <KeyboardDatePicker
                    margin='normal'
                    name='dateOfBirth'
                    id='dateOfBirth'
                    label={CLIENT_TITLE.dateOfBirth}
                    format='DD.MM.YYYY'
                    disableFuture
                    value={!!form.dateOfBirth ? moment(form.dateOfBirth, 'DD.MM.YYYY').toDate(): null }
                    inputValue={form.dateOfBirth || null}
                    initialFocusedDate={moment().subtract(18, 'years').toDate()}
                    onChange={this.handleDateChange('dateOfBirth')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date of birth'
                    }}
                    invalidDateMessage={errors.dateOfBirth || ''}
                  />
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <FormControl margin='normal' error={!!errors.gender} required fullWidth>
                    <AutocompleteField
                      suggestions={GENDERS}
                      name={'gender'}
                      labelKey='title'
                      valueKey='code'
                      autoOpen
                      value={form.gender}
                      inputLabel={CLIENT_TITLE.gender}
                      onSelect={this.handleAutocompleteSelect}
                    />
                    {!!errors.language && <FormHelperText error>{errors.language}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <FormControl margin='normal' error={!!errors.language} required fullWidth>
                    <AutocompleteField
                      suggestions={LANGUAGES}
                      name={'language'}
                      labelKey='title'
                      valueKey='code'
                      value={form.language}
                      autoOpen
                      inputLabel={CLIENT_TITLE.language}
                      onSelect={this.handleAutocompleteSelect}
                    />
                    {!!errors.language && <FormHelperText error>{errors.language}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={3} md={3} xs={12}>
                  <FormControlLabel
                    className={classes.checkbox}
                    control={<Checkbox checked={!!form.resident} onChange={this.handleCheckBoxChange.bind(this, 'resident')} value={true} />}
                    label='Резидент?'
                  />
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <FormControl margin='normal' error={!!errors.countryOfResidence} required fullWidth>
                    <AutocompleteField
                      suggestions={COUNTRIES}
                      disabled={!!form.resident}
                      error={errors.countryOfResidence}
                      labelKey='countryName'
                      name='countryOfResidence'
                      value={form.countryOfResidence}
                      inputLabel={CLIENT_TITLE.countryOfResidence}
                      onSelect={this.handleAutocompleteSelect}
                    />
                    {!!errors.countryOfResidence && <FormHelperText error>{errors.countryOfResidence}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <FormField name='region' value={form.region} label={CLIENT_TITLE.region} onChange={this.handleInputChange} />
                </Grid>
                <Grid item sm={6} md={6} xs={12}>
                  <FormField name='address' value={form.address} label={CLIENT_TITLE.address} onChange={this.handleInputChange} />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item sm={4} md={3} xs={12}>
                  <FormField name='documents' value={form.documents} label={CLIENT_TITLE.documents} onChange={this.handleInputChange} />
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <FormField name='bankAccounts' value={form.bankAccounts} label={CLIENT_TITLE.bankAccounts} onChange={this.handleInputChange} />
                </Grid>
              </Grid>
              <FormGroup row>
                <FormControlLabel
                  className={classes.checkbox}
                  control={<Checkbox checked={!!form.receiveAds} onChange={this.handleCheckBoxChange.bind(this, 'receiveAds')} value={true} />}
                  label='Получает рекламу?'
                />
              </FormGroup>
              <Box mt={3}>
                <Button variant='contained' size='large' className={classes.buttonRight} onClick={() => this.props.history.push('/')}>
                  Отменить
                </Button>
                {id ? (
                  <Button type='submit' variant='contained' color='primary' size='large'>
                    Сохранить
                  </Button>
                ) : (
                  <Button type='submit' variant='contained' className={classes.success} size='large'>
                    Добавить
                  </Button>
                )}
              </Box>
            </Box>
          </Paper>
        </form>
      </div>
    )
  }
}

export default withSnackbar(withStyles(styles)(ClientEdit))
