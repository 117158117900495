import React from "react";
import { Box, IconButton } from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

function TableActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;
  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage)));
  }

  return (
    <Box display="flex">
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page === Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page === Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page">
        <LastPageIcon />
      </IconButton>
    </Box>
  );
}

export default TableActions;
